import { Api } from '@api/apiV2';
import { useGlobalKernelStatusProps as useGlobalKernelStatusIndicatorProps } from '@components/GlobalKernelStatusIndicator';
import {
  AIFFEL_LOGO_URL,
  BUILD_USAGE,
  ORM_MEMBER_URL,
  ORM_PORTAL_DASHBOARD_URL,
  ORM_PORTAL_URL,
} from '@constants';
import usePathNameAllowedGNB from '@hooks/useAllowGNB';
import { AnimatedBox } from '@layout';
import { Typhography } from '@online/aui/Typhography';
import routes from '@routes';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { logout, selectToken, selectUser } from '@store/slices';
import { NBCButton } from '@stories';
import { User } from '@types';
import { Sentry, useCampusInfo } from '@utils';
import { useWindowSizeTracker } from '@utils/windowSizeTracker';
import classNames from 'classnames';
import { Squash as Hamburger } from 'hamburger-react';
import mobileDrawerOpenedState from '../../mobile-detector/recoil/atom/mobileDrawerState';
import { Code, User as UserIcon, UserCircle } from 'phosphor-react';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useRoutes,
} from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { kernelConnector } from '../../hooks/useKernelConnector';
import { withEvent } from '../../withEvent';
import { ForumIcon, HomeIcon, SpeakerIcon } from './icons';
import styles from './index.module.scss';
import ProfileButton from './ProfileButton';
import { MarketingBillboard } from './MarketingBillboard';
import { Carousel } from './MarketingBillboard/Carousel';
import { REFERRAL_TEXT_KOR_LIST } from './MarketingBillboard/Carousel/referrelData';
import useCookieManager from '@hooks/useCookieManager';
import useOrmBizUrls from '@hooks/useOrmBizUrls';

type THeaderLogoObj = {
  src: string;
  alt: string;
  height: string;
};

const aiffelCampusLogoObj: THeaderLogoObj = {
  src: '/images/footer/aiffel_logo-campus.png',
  alt: '아이펠 로고, 캠퍼스, 블랙',
  height: '32px',
};

const ormLogoObj: THeaderLogoObj = {
  src: '/images/orm-logo-green-v1.png',
  alt: '오름 로고, kdc, 블랙',
  height: '32px',
};

const aiffelBizLogoObj: THeaderLogoObj = {
  src: '/images/footer/aiffel_logo-default.png',
  alt: '아이펠 로고, biz, black',
  height: '32px',
};

const getAiffelLogo = () => {
  switch (BUILD_USAGE) {
    case 'legacy':
      return aiffelCampusLogoObj;
    case 'kdc':
      return ormLogoObj;
    case 'online':
    default:
      return aiffelBizLogoObj;
  }
};

const defaultAppBarLinkMenuList = [
  {
    title: '내강의실',
    link: routes.common.ClassroomPageRoute.path,
    loginRequired: true,
  },
  {
    title: '공지사항',
    link: routes.common.NoticeBoardPageRoute.path,
    loginRequired: true,
  },
];

const KDCAppBarLinkMenuList = defaultAppBarLinkMenuList;

const SCREEN_WIDTH_THRESHOLD = 768;

interface AppBarProps {
  setIsLoggingOut: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AppBar({ setIsLoggingOut }: AppBarProps) {
  const user = useAppSelector(selectUser);
  const { hasToken, campusId } = useAppSelector((state) => {
    return {
      hasToken: Boolean(state.auth.decodedToken),
      campusId: state.auth.decodedToken?.local_school_id,
    };
  });
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  // const globalKernelStatusIndicatorProps =
  //   useGlobalKernelStatusIndicatorProps();

  const isAllowedPathName = usePathNameAllowedGNB(location.pathname);

  const screenWidth = useWindowSizeTracker();

  const { removeSessionCookie } = useCookieManager();

  const handleLogoutAction = useCallback(async () => {
    setIsLoggingOut(true);
    /* NOTE: 실제로는 사용자가 로컬만 가능하거나 클라우드가 사용가능하지만
     * 현재 로컬을 사용중인 경우 해당이 되어야 할 것 같은데
     * 테스트 결과 클라우드 정보가 있는데 계정설정에서 클라우드모드 토글을 로컬로 변경한 경우가 엣지케이스 발생함.
     * 그래서 일단 localonly 만 바로 로그인 하고 나머진 체크 후 로그아웃으로 작업. (석현: 창덕님 로그인이 아니라 로그아웃 아닌가요?)
     */

    // TODO: 컨테이너가 연결 중일 떄 종료하는 케이스 잡아내서 처리해야 함
    if (user?.role && !user?.role?.localKernelOnly) {
      let hasWorkspace = false;
      const sentryReportMessage = 'failed_comcomapi_before_logout';
      try {
        const res = await Api.comcom.fetchUserStatus();
        hasWorkspace = Boolean(res.data?.userInfo?.useWorkspaceInfo);
      } catch (error: any) {
        Sentry.captureEvent({
          message: sentryReportMessage,
          extra: { error, functionName: 'getUserContainerStatus' },
        });
      }
      if (hasWorkspace) {
        try {
          const res = await Api.comcom.postTerminateContainer();
        } catch (error: any) {
          Sentry.captureEvent({
            message: sentryReportMessage,
            extra: { error, functionName: 'terminateUserContainer' },
          });
        }
      }
    }
    // Pending || Connected 상태 일때는 종료함 (w/ 창덕)
    if (kernelConnector.connectionState !== 'NotConnected') {
      kernelConnector.disconnect();
    }
    dispatch(logout());

    const isKDCBuild = BUILD_USAGE === 'kdc';

    if (isKDCBuild) {
      removeSessionCookie();
      // window.location.href = ORM_MEMBER_URL as string;
    } else {
      navigate({ pathname: '/' }, { state: { origin: 'navbar' } });
    }
    setIsLoggingOut(false);
  }, [dispatch, navigate, user?.role, setIsLoggingOut, removeSessionCookie]);

  /**
   * NOTE  사이트별, 유저정보 유/무 별 AppBar 조건 구분하기
   */

  //#region KDC
  if (BUILD_USAGE === 'kdc' || BUILD_USAGE === 'online') {
    return (
      <header className={styles.header} id={'globalHeader'}>
        <div className={styles.container}>
          <div className={styles.left}>
            <AppBarLeft />
          </div>
          {isAllowedPathName === true && (
            <div
              className={classNames(
                styles.center,
                'items-center justify-center overflow-hidden',
              )}
            >
              {BUILD_USAGE === 'kdc' && screenWidth >= SCREEN_WIDTH_THRESHOLD && (
                <div className="mr-[80px]">
                  <Carousel data={REFERRAL_TEXT_KOR_LIST} />
                </div>
              )}
            </div>
          )}
          {isAllowedPathName === true && (
            <AppBarMenu user={user} hasToken={hasToken} />
          )}
          {isAllowedPathName === true && (
            <div className={styles.right}>
              <AppBarRight
                onLogout={handleLogoutAction}
                showSessionTimer
                user={user}
              />
              {/* 로그아웃 버튼은 A Token일때만 보이고, 캠퍼스 아이디를 가진 Token B를 받으면 아이콘이 뜬다. */}
              {hasToken && !user && screenWidth > SCREEN_WIDTH_THRESHOLD && (
                <NBCButton
                  title="로그아웃"
                  style={{ width: '122px' }}
                  onClick={handleLogoutAction}
                />
              )}
              {/* {!user &&
                !hasToken &&
                BUILD_USAGE === 'kdc' &&
                screenWidth > SCREEN_WIDTH_THRESHOLD && (
                  <NBCButton
                    title="로그인"
                    style={{ width: '122px' }}
                    onClick={() =>
                      navigate(routes.common.IntegratedSignInPageRoute.path)
                    }
                  />
                )} */}
            </div>
          )}
        </div>
      </header>
    );
  }
  //#endregion

  //#region CAMPUS

  if (BUILD_USAGE === 'legacy') {
    if (!user) {
      return null;
    } else {
      return (
        <header className={styles.header}>
          <div className={styles.container}>
            <div className={styles.left}>
              <AppBarLeft />
            </div>
            <div
              className={classNames(
                styles.center,
                'items-center justify-center overflow-hidden',
              )}
            >
              <Carousel data={REFERRAL_TEXT_KOR_LIST} />
            </div>
            <div className={styles.right}>
              <AppBarRight
                user={user}
                onLogout={handleLogoutAction}
                showSessionTimer
              />
            </div>
          </div>
        </header>
      );
    }
  }

  //#endregion

  return null;
}

// #region Appbar component
function AppBarLeft() {
  const ormbizUrls = useOrmBizUrls();

  if (BUILD_USAGE === 'kdc') {
    return (
      <a
        className={styles.logo}
        href={`${ormbizUrls.ORMBIZ_PORTAL_DASHBOARD_URL}`}
      >
        <img
          src={getAiffelLogo().src}
          alt={getAiffelLogo().alt}
          style={{ height: getAiffelLogo().height }}
        />
      </a>
    );
  } else {
    return (
      <Link
        className={styles.logo}
        onClick={() => {
          withEvent({
            category: 'Prev',
            action: 'Aiffel Logo',
          });
        }}
        to={'./'}
      >
        <img
          src={getAiffelLogo().src}
          alt={getAiffelLogo().alt}
          style={{ height: getAiffelLogo().height }}
        />
      </Link>
    );
  }
}

function AppBarCenter({ user }: { user?: User.TStoreUserState }) {
  const { currentCampusId } = useCampusInfo();

  let navRoutes: { icon: React.ComponentType; path: string; title: string }[];

  switch (BUILD_USAGE) {
    case 'online':
    case 'kdc':
      navRoutes = [
        {
          icon: HomeIcon,
          path: routes.common.ClassroomPageRoute.path,
          title: '나의 강의실',
        },
        {
          icon: SpeakerIcon,
          path: routes.common.NoticeBoardPageRoute.path,
          title: '공지사항',
        },
        {
          icon: ForumIcon,
          path: routes.common.ForumPageRoute.path,
          title: '포럼',
        },
      ];
      break;
    default:
      navRoutes = [];
  }
  // NOTE Tue/0927 포럼은 현재 사용 하지 않으므로 comment out, user 정보 받아오지 않아도 됨
  // if (!user.hideForum && BUILD_USAGE !== 'kdc') {
  //   navRoutes.push({
  //     icon: ForumIcon,
  //     path: routes.common.ForumPageRoute.path,
  //     title: '포럼',
  //   });
  // }

  return (
    <nav className={styles.appBarCenter}>
      {currentCampusId &&
        Number(currentCampusId) > 0 &&
        navRoutes.map((item, key) => (
          <NavLink
            className={({ isActive }) =>
              classNames({ [styles.active]: isActive }, 'group')
            }
            to={item.path}
            key={key}
          >
            <span
              className={classNames(
                styles.iconWrapper,
                'group-hover:fill-[#442d00]',
              )}
            >
              <item.icon />
            </span>
            <span
              className={classNames(
                styles.title,
                'group-hover:color-[#442d00]',
              )}
            >
              {item.title}
            </span>
          </NavLink>
        ))}
    </nav>
  );
}

function AppBarRight({
  showSessionTimer,
  user,
  onLogout,
}: {
  showSessionTimer: boolean;
  user: User.TStoreUserState | null;
  onLogout?: () => void;
}) {
  const screenWidth = useWindowSizeTracker();
  const [isOpen, setOpen] = useState(false);

  const [isMobileDrawerOpend, setMobileDrawerOpend] = useRecoilState(
    mobileDrawerOpenedState,
  );

  const onClickHamburgerMenu = () => {
    setMobileDrawerOpend((flag: boolean) => !flag);
  };

  useEffect(() => {
    if (isMobileDrawerOpend === true) {
      if (typeof window != 'undefined' && window.document) {
        document.body.style.overflow = 'hidden';
      }
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isMobileDrawerOpend]);

  return (
    <div className={classNames(styles.appBarRight, user && 'flex-col')}>
      {/*{BUILD_USAGE === 'legacy' && <NotificationButton />}*/}
      {!user ? (
        screenWidth <= SCREEN_WIDTH_THRESHOLD && (
          <div className={styles.hamburgerWrapper}>
            <Hamburger toggled={isOpen} toggle={setOpen} size={20} />
            <AnimatedBox>{isOpen && <DropdownMenu />}</AnimatedBox>
          </div>
        )
      ) : screenWidth <= SCREEN_WIDTH_THRESHOLD ? (
        <>
          <Hamburger
            toggled={isMobileDrawerOpend}
            toggle={onClickHamburgerMenu}
            size={20}
          />
        </>
      ) : (
        <ProfileButton onLogout={onLogout} />
      )}
    </div>
  );
}
// #endregion

function AppBarMenu({
  user,
  hasToken,
}: {
  user: User.TStoreUserState | null;
  hasToken: boolean;
}) {
  return (
    <div className={styles.appBarMenu}>
      <ul className={styles.appBarMenuList}>
        {/* {BUILD_USAGE === 'kdc' &&
          !hasToken &&
          KDCAppBarLinkMenuList.map((item, idx) => {
            if (item.loginRequired === false) {
              return <AppBarMenuItem key={idx} item={item} idx={idx} />;
            } else {
              return null;
            }
          })}
        {BUILD_USAGE === 'kdc' &&
          hasToken &&
          KDCAppBarLinkMenuList.map((item, idx) => {
            if (item.loginRequired === true) {
              return <AppBarMenuItem key={idx} item={item} idx={idx} />;
            } else {
              return null;
            }
          })} */}
        {BUILD_USAGE === 'online' &&
          !user &&
          defaultAppBarLinkMenuList.map((item, idx) => {
            if (item.loginRequired === false) {
              return <AppBarMenuItem key={idx} item={item} idx={idx} />;
            }
            return null;
          })}
        {BUILD_USAGE === 'online' &&
          user &&
          defaultAppBarLinkMenuList.map((item, idx) => {
            if (item.loginRequired === true)
              return <AppBarMenuItem key={idx} item={item} idx={idx} />;
            return null;
          })}
      </ul>
    </div>
  );
}

function AppBarMenuItem({
  item,
  idx,
}: {
  item: { title: string; link: string; loginRequired: boolean };
  idx: number;
}) {
  return (
    <li key={idx} className={styles.appBarMenuListItem}>
      <NavLink
        className={({ isActive }) =>
          classNames({ [styles.active]: isActive }, 'group')
        }
        to={item.link}
      >
        {item.title}
      </NavLink>
    </li>
  );
}

// #region 석현님 통합 로그인 메뉴바
/* NOTE: 석현님 작업메뉴와 합치는 코드 입니다.
 *
 */
type THeaderButtonData = {
  children?: React.ReactNode;
  to?: string;
  variant?: 'primary' | 'secondary' | 'tertiary';
  publicOnly?: boolean;
};
const headerButtonMap: THeaderButtonData[] = [
  {
    children: '강의목록',
    to: routes.kdc.FindClassPageRoute.path,
    variant: 'tertiary',
  },
  {
    children: '회원가입',
    to: routes.common.IntegratedSignUpPageRoute.path,
    variant: 'secondary',
    publicOnly: true,
  },
  {
    children: '로그인',
    to: routes.common.IntegratedSignInPageRoute.path,
    variant: 'primary',
    publicOnly: true,
  },
];

const DropdownMenu: React.FC = () => {
  const hasToken = useAppSelector((state) => Boolean(state.auth.token));
  return (
    <div className={styles.dropdownWrapper}>
      {/* {headerButtonMap.map((props, key) => {
        if (props.publicOnly && hasToken) return;
        return (
          <div className={styles.dropdownItem}>
            <a href={props.to}>{props.children}</a>
          </div>
        );
      })} */}
    </div>
  );
};

// #endregion

// #region MobileHamburgurMenu
interface MobileHamburgerMenuViewProps {
  user?: User.TStoreUserState | null | undefined;
  onClose?: (flag: boolean) => void;
}

export const MobileHamburgurMenuView = ({
  user,
  onClose,
}: MobileHamburgerMenuViewProps) => {
  const ormbizUrls = useOrmBizUrls();

  const username = user?.name;

  const [_, setMobileDrawerOpend] = useRecoilState(mobileDrawerOpenedState);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const onCloseDrawerMenu = () => {
    setMobileDrawerOpend(false);

    document.body.style.overflow = 'unset';
  };

  const handleMoveToORM = () => {
    window.open(ormbizUrls.ORMBIZ_PORTAL_DASHBOARD_URL, '_self')?.focus();
  };

  const handleLogoutAction = useCallback(async () => {
    /* NOTE: 실제로는 사용자가 로컬만 가능하거나 클라우드가 사용가능하지만
     * 현재 로컬을 사용중인 경우 해당이 되어야 할 것 같은데
     * 테스트 결과 클라우드 정보가 있는데 계정설정에서 클라우드모드 토글을 로컬로 변경한 경우가 엣지케이스 발생함.
     * 그래서 일단 localonly 만 바로 로그인 하고 나머진 체크 후 로그아웃으로 작업. (석현: 창덕님 로그인이 아니라 로그아웃 아닌가요?)
     */
    onCloseDrawerMenu();
    // TODO: 컨테이너가 연결 중일 떄 종료하는 케이스 잡아내서 처리해야 함
    if (user?.role && !user?.role?.localKernelOnly) {
      let hasWorkspace = false;
      const sentryReportMessage = 'failed_comcomapi_before_logout';
      try {
        const res = await Api.comcom.fetchUserStatus();
        hasWorkspace = Boolean(res.data?.userInfo?.useWorkspaceInfo);
      } catch (error: any) {
        Sentry.captureEvent({
          message: sentryReportMessage,
          extra: { error, functionName: 'getUserContainerStatus' },
        });
      }
      if (hasWorkspace) {
        try {
          const res = await Api.comcom.postTerminateContainer();
        } catch (error: any) {
          Sentry.captureEvent({
            message: sentryReportMessage,
            extra: { error, functionName: 'terminateUserContainer' },
          });
        }
      }
    }
    // Pending || Connected 상태 일때는 종료함 (w/ 창덕)
    if (kernelConnector.connectionState !== 'NotConnected') {
      kernelConnector.disconnect();
    }
    dispatch(logout());
    // navigate({ pathname: '/' }, { state: { origin: 'navbar' } });
  }, [dispatch, navigate, user?.role]);

  return (
    <div className={styles.mobileHamburgurMenu}>
      <div className={styles.hamburgerProfile}>
        <div className={styles.profileTop}>
          <Typhography.Subtitle2
            fontWeight={700}
            sm={'Subtitle2'}
          >{`${username} 님! 환영해요 :)`}</Typhography.Subtitle2>
        </div>
        {/* <div className={styles.profileBottom}>
          <div className={styles.myClassroom}>
            <div className={styles.iconLinkWithLabel}>
              <Link
                to={routes.common.ClassroomPageRoute.path}
                onClick={onCloseDrawerMenu}
              >
                <Code size={24} />
                <Typhography.Subtitle2 fontWeight={700}>
                  내 강의실
                </Typhography.Subtitle2>
              </Link>
            </div>
          </div>
          <div className={styles.dividerVertical} />
          <div className={styles.myPage}>
            <div className={styles.iconLinkWithLabel}>
              <Link
                to={routes.common.AccountPageRoute.path}
                onClick={onCloseDrawerMenu}
              >
                <UserIcon size={24} />
                <Typhography.Subtitle2 fontWeight={700}>
                  마이페이지
                </Typhography.Subtitle2>
              </Link>
            </div>
          </div>
        </div> */}
        <div className={styles.dividerHorizontal} />
      </div>
      <div className={styles.hamburgerMenuLink}>
        <ul>
          <li>
            <span
              className={styles.hamburgerMenuExternalLink}
              onClick={handleMoveToORM}
            >
              내 학습실
            </span>
          </li>
          <li>
            <span
              className={styles.hamburgerMenuExternalLink}
              onClick={handleLogoutAction}
            >
              로그아웃
            </span>
          </li>
          {/* {mobileNavLinkList.map((mobile, idx) => {
            if (mobile.type === 'inner') {
              return (
                <li>
                  <Link to={mobile.link} onClick={onCloseDrawerMenu}>
                    {mobile.title}
                  </Link>
                </li>
              );
            } else {
              return (
                <li>
                  <a
                    href={mobile.link}
                    target="_blank"
                    rel="noreferrer"
                    onClick={onCloseDrawerMenu}
                  >
                    {mobile.title}
                  </a>
                </li>
              );
            }
          })} */}
        </ul>
      </div>
      {/* <div className={styles.hamburgerMenuFooter}>
        <ul>
          {BUILD_USAGE === 'kdc' && (
            <li>
              <span onClick={handleMoveToORM}>내 학습실</span>
            </li>
          )}
          <li>
            <span onClick={handleLogoutAction}>로그아웃</span>
          </li>
        </ul>
      </div> */}
    </div>
  );
};
// #endregion
const mobileNavLinkList = [
  {
    title: '강의찾기',
    link: routes.kdc.FindClassPageRoute.path,
    type: 'inner',
  },
  {
    title: '공지사항',
    link: routes.common.NoticeBoardPageRoute.path,
    type: 'inner',
  },
];
